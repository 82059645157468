openExternalLinksInNewTab();
buildEmailLinks('a.email');
tooltips.init();

ssm.addState({
  id: 'mobile',
  query: '(max-width: 767px)',
  onEnter: function(){
    clipToEdgeOnOverflow.apply('.tdi--text-overflow-wrapper');
  },
  onResize: function(){
    clipToEdgeOnOverflow.apply('.tdi--text-overflow-wrapper');
  },
	onLeave: function(){
    clipToEdgeOnOverflow.undo();
	}
});
